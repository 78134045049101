<template>
  <v-container fluid>
    <SharedToolbar enableSidebar />
    <v-card>
      <v-card-title>レポート</v-card-title>
      <v-card-text>
        <reports
          :isLoading="isLoading"
          :reports="reports"
          @createReport="onCreateReport"
          @downloadSummary="onDownloadSummary"
          @downloadBreakdown="onDownloadBreakdown"
        />
      </v-card-text>
      <a v-show="false" ref="doDownload" :href="reportURL" :download="filename">click</a>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { adminCreateReports, adminGetReports } from '../services/functions'
import SharedToolbar from '../components/SharedToolbar'
import Reports from '../components/Reports'

export default {
  components: {
    SharedToolbar,
    Reports
  },
  computed: {
    ...mapState(['admin'])
  },
  data: () => ({
    isLoading: false,
    timeZone: '',
    reportURL: '',
    reports: [],
    filename: ''
  }),
  methods: {
    async onCreateReport ({ startDate, endDate }) {
      this.isLoading = true
      await adminCreateReports({ startDate, endDate, timeZone: this.timeZone })
      const { data: reports } = await adminGetReports({ company: '', timeZone: this.timeZone })
      this.reports = reports
      this.isLoading = false
    },
    onDownloadSummary (reportURL) {
      this.reportURL = reportURL
      this.filename = 'summary.csv'
      this.$nextTick(() => {
        this.$refs.doDownload.click()
      })
    },
    onDownloadBreakdown (reportURL) {
      this.reportURL = reportURL
      this.filename = 'breakdown.csv'
      this.$nextTick(() => {
        this.$refs.doDownload.click()
      })
    }
  },
  async created () {
    this.isLoading = true
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const { data: reports } = await adminGetReports({ company: '', timeZone: this.timeZone })
    this.reports = reports
    this.isLoading = false
  }
}
</script>
