
<template>
  <v-col>
    <v-row aligh="start" dense>
      <v-col cols="auto">
        <v-btn text @click="pickStart()">
          <v-icon left>mdi-calendar</v-icon>
          開始日({{ startDate }})
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn text @click="pickEnd()">
          <v-icon left>mdi-calendar</v-icon>
          終了日({{ endDate }})
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn text @click="createReport()" :disabled="!isValidDates()"
          >レポートCSV生成</v-btn
        >
      </v-col>
    </v-row>
    <v-dialog
      ref="startDialog"
      v-model="startDialog"
      :return-value.sync="startDate"
      persistent
      width="290px"
    >
      <v-date-picker v-model="startDate" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="cancelStart()">Cancel</v-btn>
        <v-btn text color="primary" @click="$refs.startDialog.save(startDate)"
          >OK</v-btn
        >
      </v-date-picker>
    </v-dialog>
    <v-dialog
      ref="endDialog"
      v-model="endDialog"
      :return-value.sync="endDate"
      persistent
      width="290px"
    >
      <v-date-picker v-model="endDate" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="cancelEnd()">Cancel</v-btn>
        <v-btn text color="primary" @click="$refs.endDialog.save(endDate)"
          >OK</v-btn
        >
      </v-date-picker>
    </v-dialog>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="reports"
        sort-by="createdAt"
        sort-desc
        :loading="isLoading"
      >
        <template v-slot:item.summary="{ item }">
          <v-btn small @click="downloadSummary(item)">
            {{ item.summaryFilename }}
            <v-icon right>mdi-cloud-download</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.breakdown="{ item }">
          <v-btn small @click="downloadBreakdown(item)">
            {{ item.breakdownFilename }}
            <v-icon right>mdi-cloud-download</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'reports',
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    reports: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    startDialog: false,
    endDialog: false,
    startDate: '',
    endDate: '',
    headers: [
      {
        text: '作成日時',
        align: 'center',
        sortable: true,
        value: 'createdAt'
      },
      {
        text: '開始日',
        align: 'center',
        sortable: true,
        value: 'startDate'
      },
      {
        text: '終了日',
        align: 'center',
        sortable: true,
        value: 'endDate'
      },
      {
        text: 'サマリーCSV',
        align: 'right',
        sortable: true,
        value: 'summary'
      },
      {
        text: '内訳CSV',
        align: 'right',
        sortable: true,
        value: 'breakdown'
      }
    ]
  }),
  methods: {
    pickStart () {
      this.startDialog = true
    },
    pickEnd () {
      this.endDialog = true
    },
    cancelStart () {
      this.startDate = ''
      this.startDialog = false
    },
    cancelEnd () {
      this.endDate = ''
      this.endDialog = false
    },
    isValidDates () {
      if (this.startDate === '' || this.endDate === '') {
        return false
      } else {
        return this.startDate <= this.endDate
      }
    },
    createReport () {
      this.$emit('createReport', { startDate: this.startDate, endDate: this.endDate })
    },
    downloadSummary (item) {
      this.$emit('downloadSummary', item.summary)
    },
    downloadBreakdown (item) {
      this.$emit('downloadBreakdown', item.breakdown)
    }
  }
}
</script>
